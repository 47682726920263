<template>
  <b-card>
    <!-- Form -->
    <b-card-body>
      <b-form class="mt-2">
        <b-row>
          <template v-for="setting in organizationSettings">
            <b-col :key="setting._id" sm="6">
              <b-form-group :label="setting.title" :label-for="setting.key">
                <b-form-select
                  v-on:change="handleSettingValueChange($event, setting.key)"
                  v-model="setting.value"
                  :options="settingOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BCardText,
  BCardBody,
  BCardHeader,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
import ResponseMixins from "../../mixins/ResponseMixins";
export default {
  name: "OrganizationSettings",
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BImg,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BModal,
    ToastificationContent,
  },
  data() {
    return {
      organizationSettings: [],
      settingOptions: [
        { value: 1, text: "Very Low" },
        { value: 2, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 4, text: "High" },
        { value: 5, text: "Critical" },
      ],
    };
  },
  mixins: [ResponseMixins],
  mounted() {
    this.getOrganizationSettings();
  },
  methods: {
    getScoreValueFromNumber(num) {
      switch (num) {
        case 1:
          return "Very Low";
        case 2:
          return "Low";
        case 3:
          return "Medium";
        case 4:
          return "High";
        case 5:
          return "Critical";
        default:
          return "Very Low";
      }
    },

    getOrganizationSettings() {
      const options = {
        method: "GET",
        url: `${process.env.VUE_APP_BASEURL}/organization-settings`,
      };
      this.$http(options)
        .then((res) => {
          this.organizationSettings = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleSettingValueChange(value, key) {
      const bodyFormData = new FormData();
      bodyFormData.append("key", key);
      bodyFormData.append("value", value);
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/organization-settings`,
        data: bodyFormData,
      };
      this.$http(options)
        .then((res) => {
          this.handleResponse(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
  },
};
</script>

<style>
</style>