

export default {
    name: 'RoleMixins',
    methods: {
        getRoles(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/roles`,
                    params: params,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },

        createRole(obj) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                bodyFormData.append("name", obj.name);
                bodyFormData.append("description", obj.description);
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/roles`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },


        addRoleToUser(obj){
            return new Promise((resolve, reject)=>{
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/users/${obj.userId}/assign-role/${obj.roleId}`,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        removeRoleFromUser(obj){
            return new Promise((resolve, reject)=>{
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/users/${obj.userId}/remove-role/${obj.roleId}`,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },



    },
}