<template>
  <b-card>
    <!-- modal -->
    <b-modal ref="my-modal" hide-footer title="Create New department" size="lg">
      <validation-observer ref="CreateNewDepartmentForm" v-slot="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleNewDepartmentSubmit">
          <!-- Title -->
          <b-col>
            <b-form-group label="Name">
              <template slot="label">
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="newDepartment.title"
                  :state="errors.length > 0 ? false : null"
                  name="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Description -->
          <b-col>
            <b-form-group label="Description" label-for="description">
              <validation-provider #default="{ errors }" name="description">
                <!-- <b-form-input
                  id="description"
                  v-model="newDepartment.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                /> -->
                <b-form-textarea
                  id="description"
                  v-model="newDepartment.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="d-flex justify-content-end">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
            >
              Create
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Form -->
    <b-card-body>
      <div class="media">
        <div class="media-aside align-self-start">
          <b-img
            v-bind="mainProps"
            src="../../assets/images/avatars/14.png"
            rounded="circle"
            alt="Circle image"
          ></b-img>
        </div>
      </div>
      <validation-observer
        ref="AccountSettingsGeneralForm"
        #default="{ invalid }"
      >
        <b-form class="mt-2" @submit.prevent="handleUserUpdateSubmit">
          <b-row>
            <!-- FirstName -->
            <b-col sm="6">
              <b-form-group label="First Name">
                <template slot="label">
                  First Name <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="first-name"
                    v-model="formData.firstName"
                    :state="errors.length > 0 ? false : null"
                    name="First Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- LastName -->
            <b-col sm="6">
              <b-form-group label="Last Name">
                <template slot="label">
                  Last Name <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="last-name"
                  rules="required"
                >
                  <b-form-input
                    id="last-name"
                    v-model="formData.lastName"
                    :state="errors.length > 0 ? false : null"
                    name="last-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col sm="6">
              <b-form-group label="Email">
                <template slot="label">
                  Email <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="formData.email"
                    :state="errors.length > 0 ? false : null"
                    name="email"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Mobile -->
            <b-col sm="6">
              <b-form-group label="Mobile">
                <template slot="label">
                  Mobile <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="mobile"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-form-input
                    id="mobile"
                    v-model="formData.mobile"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    name="mobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Designation -->
            <b-col sm="6">
              <b-form-group label="Designation">
                <template slot="label">
                  Designation <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="designation"
                  rules="required"
                >
                  <b-form-input
                    id="designation"
                    v-model="formData.designation"
                    :state="errors.length > 0 ? false : null"
                    name="designation"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--Department-->
            <b-col sm="6">
              <b-row>
                <b-col sm="12">
                  <b-form-group label="Department">
                    <template slot="label">
                      Department <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="department"
                      rules="required"
                    >
                      <b-form-select
                        selected
                        v-model="formData.department"
                        :options="departmentOptions"
                        :state="errors.length > 0 ? false : null"
                      ></b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-button
                  @click="showModal"
                  type="button"
                  variant="primary"
                  size="sm"
                  class="ml-1"
                  ><feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle">New Department</span></b-button
                >
              </b-row>
            </b-col>

            <!-- submit and reset -->
            <b-col sm="12" class="mt-75">
              <b-button
                type="submit"
                variant="success"
                class="mr-1"
                :disabled="invalid || !isDirty"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                Apply Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardBody,
  BCardHeader,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BRow,
  BCol,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserMixins from "../../mixins/UserMixins";
import ResponseMixins from "../../mixins/ResponseMixins";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BFormTextarea,
    BImg,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    required,
    BFormSelect,
    BModal,
    Ripple,
    ToastificationContent,
  },
  data() {
    return {
      newDepartment: {
        title: "",
        description: "",
      },
      mainProps: {
        width: 75,
        height: 75,
        class: "m1",
      },
      initialFormData: {
        firstName: "",
        lastName: "",
        email: "",
        designation: "",
        mobile: null,
        department: null,
      },
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        designation: "",
        mobile: null,
        department: null,
      },
      departmentOptions: [{ value: null, text: "--Select Department--" }],
    };
  },
  name: "AccountSettingsGeneral",
  mixins: [UserMixins, ResponseMixins],

  computed: {
    isDirty() {
      // Check if the current form data is different from the initial form data
      return (
        JSON.stringify(this.formData) !== JSON.stringify(this.initialFormData)
      );
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getAllDepartments();
      this.getCurrentUserDetailsAndSetData();
    },

    getCurrentUserDetailsAndSetData() {
      this.getCurrentUserDetails()
        .then((resp) => {
          this.formData.firstName = resp.data.data.firstname;
          this.formData.lastName = resp.data.data.lastname;
          this.formData.email = resp.data.data.email;
          this.formData.mobile = Number(resp.data.data.mobile);

          this.initialFormData.firstName = resp.data.data.firstname;
          this.initialFormData.lastName = resp.data.data.lastname;
          this.initialFormData.email = resp.data.data.email;
          this.initialFormData.mobile = Number(resp.data.data.mobile);

          if (
            !resp.data.data.designation ||
            resp.data.data.designation === null ||
            !resp.data.data.department ||
            resp.data.data.department === null
          ) {
            this.showToast(
              // "Please complete your profile & continue",
              "Complete Your Profile for a Better User Experience",
              "warning"
            );
          }

          if (resp.data.data.designation !== null) {
            this.formData.designation = resp.data.data.designation;
            this.initialFormData.designation = resp.data.data.designation;
          }
          if (resp.data.data.department !== null) {
            this.formData.department = resp.data.data.department_id;
            this.initialFormData.department = resp.data.data.department_id;
          }
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },
    getAllDepartments() {
      this.departmentOptions = [{ value: null, text: "--Select Department--" }];
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/departments?dont_paginate`,
        params: { dont_paginate: true },
      };
      this.$http(options)
        .then((res) => {
          let newDepartments = res.data.data;
          for (var i = 0; i < newDepartments.length; i++) {
            this.departmentOptions.push({
              value: newDepartments[i]._id,
              text: newDepartments[i].title,
            });
          }
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },
    handleUserUpdateSubmit() {
      this.updateCurrentUserDetails({
        email: this.formData.email,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        mobile: this.formData.mobile,
        designation: this.formData.designation,
        department: this.formData.department,
      })
        .then((res) => {
          this.$store.dispatch("app/setCurrentUserDetails", res.data.data, {
            root: true,
          });
          this.handleResponse(res);
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    handleNewDepartmentSubmit() {
      const bodyFormData = new FormData();
      bodyFormData.append("title", this.newDepartment.title);
      bodyFormData.append("description", this.newDepartment.description);
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/departments`,
        data: bodyFormData,
      };
      this.$http(options)
        .then((resp) => {
          this.getAllDepartments();
          this.hideModal();
          this.handleResponse(resp);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
  },
};
</script>

<style>
</style>