<template>
  <div class="d-flex justify-content-center align-items-center">
    <b-modal ref="my-modal" hide-footer title="Create New Role" size="lg">
      <validation-observer ref="new_role" v-slot="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleModalSubmit">
          <!-- Title -->
          <b-col>
            <b-form-group label="Name">
              <template slot="label">
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required|min:4"
              >
                <b-form-input
                  id="name"
                  v-model="newRole.name"
                  :state="errors.length > 0 ? false : null"
                  name="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Description">
              <validation-provider #default="{ errors }" name="Description">
                <b-form-textarea
                  id="textarea"
                  v-model="newRole.description"
                  rows="3"
                  max-rows="3"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="d-flex justify-content-end align-items-center">
            <b-button type="submit" variant="primary" :disabled="invalid">
              Create
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-sidebar
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <RoleDetailsForm
        v-if="selectedRoleIndex !== null && roles"
        :role="roles[selectedRoleIndex]"
        :closeSidebar="closeSidebar"
        :loadRoles="
          () => {
            load();
          }
        "
      />
    </b-sidebar>
    <div style="width: 80%" class="card">
      <div class="card-header">
        <h2 class="mb-0">User Roles</h2>
      </div>
      <div class="card-body">
        <div class="todo-application">
          <div class="content-area-wrapper">
            <div class="sidebar-left">
              <div class="sidebar">
                <div class="sidebar-content todo-sidebar">
                  <div class="todo-app-menu">
                    <div class="add-task">
                      <b-button @click="showModal()" variant="primary" block
                        >Add Role</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="content-right">
              <div class="content-body">
                <b-overlay
                  :show="showOverlay"
                  opacity="0.17"
                  blur="1rem"
                  rounded="md"
                  variant="secondary"
                >
                  <div class="todo-app-list">
                    <div
                      class="app-fixed-search d-flex align-items-center justify-content-between"
                    >
                      <div
                        class="d-flex align-content-center justify-content-between w-75"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="filters.search"
                            placeholder="Search Roles..."
                          ></b-form-input>
                        </b-input-group>
                      </div>
                    </div>

                    <vue-perfect-scrollbar
                      :settings="{ maxScrollbarLength: 120 }"
                      class="todo-task-list-wrapper list-group scroll-area"
                    >
                      <draggable
                        v-if="roles"
                        v-model="roles"
                        handle="draggable-task-handle"
                        tag="ul"
                        class="todo-task-list media-list"
                        id="todo-task-list"
                      >
                        <li
                          v-for="role in roles"
                          :key="role._id"
                          class="todo-item"
                          @click="openSidebar(role._id)"
                        >
                          <div class="todo-title-wrapper">
                            <div class="todo-title-area">
                              <div class="title-wrapper">
                                <span class="todo-title">{{
                                  role.display_name
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </li>
                      </draggable>
                    </vue-perfect-scrollbar>
                  </div>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="table-responsive">
          <table class="table">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col" class="header">Name</th>
              </tr>
            </thead>
          </table>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BOverlay,
  BSidebar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BModal,
  BForm,
  BFormGroup,
  BCol,
  BFormTextarea,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import RoleMixins from "../../mixins/RoleMixins";
import RoleDetailsForm from "./components/RoleDetailsForm.vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import UtilsMixins from "../../mixins/UtilsMixins";

export default {
  name: "RoleSettings",
  components: {
    VuePerfectScrollbar,
    draggable,
    BSidebar,
    BOverlay,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BModal,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormTextarea,
    RoleDetailsForm,
  },
  mixins: [RoleMixins, ResponseMixins, UtilsMixins],
  data() {
    return {
      selectedRoleIndex: null,
      newRole: {
        name: null,
        description: null,
      },
      roles: null,
      showOverlay: false,
      filters: {
        search: null,
      },
    };
  },
  watch: {
    filters: {
      handler: function (value) {
        this.debounceFunction(this.getRolesAndSetData(value));
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getRolesAndSetData(this.filters);
    },
    getRolesAndSetData(params) {
      this.getRoles(params)
        .then((res) => {
          this.roles = res.data.data.data;
        })
        .catch((err) => this.handleError(err));
    },
    createRoleAndSetData(obj) {
      this.createRole(obj)
        .then((res) => {
          this.roles.push(res.data.data);
          this.handleResponse(res, "Role Successfully Created.");
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    handleModalSubmit() {
      this.createRoleAndSetData(this.newRole);
      this.hideModal();
      this.newRole.name = null;
      this.newRole.description = null;
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function (id) {
      const roleIndex = this.roles.findIndex((role) => role._id === id);
      if (roleIndex !== -1) {
        this.selectedRoleIndex = roleIndex;
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      }
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-todo.scss";
</style>