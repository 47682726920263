<template>
  <b-tabs pills vertical nav-class="nav-left" nav-wrapper-class="w-40" lazy>
    <b-tab title="Settings" active>
      <template #title>
        <feather-icon size="1.3x" icon="SettingsIcon" /><span>General</span>
      </template>
      <div class="col align-items-start justify-content-start">
        <account-settings />
      </div>
    </b-tab>
    <!-- <b-tab title="Settings" >
      <template #title>
        <feather-icon size="1.3x" icon="KeyIcon" /><span>2FA</span>
      </template>
      <div class="col align-items-start justify-content-start">
        <TwoFactorAuth />
      </div>
    </b-tab> -->

    <b-tab title="Settings">
      <template #title>
        <feather-icon size="1.3x" icon="LockIcon" /><span>Password</span>
      </template>
      <div class="col align-items-start justify-content-start">
        <reset-password-setting />
      </div>
    </b-tab>

    <!-- <b-tab title="Settings">
      <template #title>
        <feather-icon size="1.3x" icon="UsersIcon" /><span>Users</span>
      </template>
      <div class="col align-items-start justify-content-start">
        <users-settings />
      </div>
    </b-tab> -->

    <b-tab title="Settings">
      <template #title>
        <feather-icon size="1.3x" icon="UsersIcon" /><span>Deleted Users</span>
      </template>
      <div class="col align-items-start justify-content-start">
        <listing />
      </div>
    </b-tab>

    <b-tab title="Settings">
      <template #title>
        <feather-icon size="1.3x" icon="UserCheckIcon" /><span>Roles</span>
      </template>
      <div class="col align-items-start justify-content-start">
        <role-settings />
      </div>
    </b-tab>

    <b-tab title="Settings">
      <template #title>
        <feather-icon size="1.3x" icon="TrelloIcon" /><span>Organization</span>
      </template>
      <div class="col align-items-start justify-content-start">
        <organization-settings />
      </div>
    </b-tab>

    <!-- <b-tab title="Settings">
      <template #title>
        <feather-icon size="1.3x" icon="StarIcon" /><span>Subscriptions</span>
      </template>
      <div class="col align-items-start justify-content-start">
        <subscriptions />
      </div>
    </b-tab> -->

    <b-tab title="Settings">
      <template #title>
        <feather-icon size="1.3x" icon="HelpCircleIcon" /><span>Other</span>
      </template>
      <div class="col align-items-start justify-content-start">
        <organization-settings />
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCard, BCardText } from "bootstrap-vue";
import AccountSettings from "@/views/Settings/AccountSettings.vue";
import OrganizationSettings from "@/views/Settings/OrganizationSettings";
import ResetPasswordSetting from "@/views/Settings/ResetPasswordSetting";
import RoleSettings from "@/views/Settings/RoleSettings";
import UsersSettings from "@/views/Settings/UsersSettings";
import TwoFactorAuth from "@/views/Settings/TwoFactorAuth.vue";
import Listing from "../DeletedUsers/Listing.vue";
import Subscriptions from "./Subscriptions.vue";

export default {
  name: "SettingsView",
  components: {
    BTab,
    BTabs,
    BCard,
    BCardText,
    AccountSettings,
    OrganizationSettings,
    ResetPasswordSetting,
    RoleSettings,
    UsersSettings,
    TwoFactorAuth,
    Listing,
    Subscriptions,
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>