<template>
  <div>
    <b-modal ref="my-modal" hide-footer title="Create New department" size="lg">
      <validation-observer ref="CreateNewDepartmentForm" v-slot="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleNewDepartmentSubmit">
          <!-- Title -->
          <b-col>
            <b-form-group label="Name">
              <template slot="label">
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="newDepartment.title"
                  :state="errors.length > 0 ? false : null"
                  name="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Description -->
          <b-col>
            <b-form-group label="Description">
              <validation-provider #default="{ errors }" name="description">
                <!-- <b-form-input
                  id="description"
                  v-model="newDepartment.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                /> -->
                <b-form-textarea
                  id="description"
                  v-model="newDepartment.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="d-flex justify-content-end">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
            >
              Create
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card">
        <div class="card-header border-bottom">
          <div style="width: 50%">
            <h2 class="mb-0">Edit User Information</h2>
          </div>

          <div class="d-flex justify-content-end align-items-center">
            <b-button @click="closeSidebar()" variant="danger"
              ><feather-icon icon="XIcon"
            /></b-button>
          </div>
        </div>
        <div class="card-body">
          <validation-observer ref="UserEditForm" #default="{ invalid }">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="onUserUpdateClick"
            >
              <b-form-group label="First Name">
                <template slot="label">
                  First Name <span class="text-danger">*</span>
                </template>
                <validation-provider
                  vid="firstname"
                  #default="{ errors }"
                  name="First Name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="first-name"
                    v-model="user.firstname"
                    :state="errors.length > 0 ? false : null"
                    name="first-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Last Name">
                <template slot="label">
                  Last Name <span class="text-danger">*</span>
                </template>
                <validation-provider
                  vid="lastname"
                  #default="{ errors }"
                  name="Last Name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="last-name"
                    v-model="user.lastname"
                    :state="errors.length > 0 ? false : null"
                    name="last-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Roles">
                <vue-autosuggest
                  :suggestions="role.suggestions"
                  :limit="5"
                  v-model="role.currentRole"
                  id="autosuggest__input"
                  :input-props="roleInputProps"
                  @input="getRolesBySearchPhraseAndSetData"
                  @selected="onRoleSelected"
                  :get-suggestion-value="getRoleSuggestionValue"
                >
                  <template
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <span style="{ display: 'flex', color: 'navyblue'}">{{
                      suggestion.item.name
                    }}</span>
                  </template>
                </vue-autosuggest>

                <div class="demo-inline-spacing">
                  <template v-for="(value, i) in role.selected">
                    <b-badge :key="i" variant="light-primary">
                      <span>{{ value.name }}</span>
                      <feather-icon
                        @click="removeRole(value._id)"
                        icon="XCircleIcon"
                        class="text-danger cursor-pointer ml-25"
                      />
                    </b-badge>
                  </template>
                </div>
              </b-form-group>

              <b-form-group label="Designation">
                <template slot="label">
                  Designation <span class="text-danger">*</span>
                </template>
                <validation-provider
                  vid="designation"
                  #default="{ errors }"
                  name="Designation"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="designation"
                    v-model="user.designation"
                    :state="errors.length > 0 ? false : null"
                    name="designation"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-8">
                  <b-form-group label="Department">
                    <template slot="label">
                      Department <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="department"
                      rules="required"
                    >
                      <b-form-select
                        selected
                        v-model="user.department_id"
                        :options="departmentOptions"
                        :state="errors.length > 0 ? false : null"
                      ></b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-button
                    @click="showModal"
                    type="button"
                    variant="primary"
                    style="margin-top: 14px"
                    ><feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">New Department</span></b-button
                  >
                </div>
              </div>

              <b-button
                type="submit"
                variant="primary"
                class="mr-1 mt-2"
                :disabled="invalid"
              >
                <feather-icon icon="CheckIcon" class="cursor-pointer mr-25" />
                Update User
              </b-button>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BBadge,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { VueAutosuggest } from "vue-autosuggest";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import UserMixins from "../../../mixins/UserMixins";
import RoleMixins from "../../../mixins/RoleMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
export default {
  components: {
    BButton,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    Ripple,
    required,
    BFormSelect,
    BBadge,
    BCol,
    BFormTextarea,
  },
  mixins: [UserMixins, ResponseMixins, RoleMixins],
  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    reloadParentData: {
      type: Function,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      roleInputProps: {
        class: "form-control",
        placeholder: "Search Roles..",
      },
      newDepartment: {
        title: "",
        description: "",
      },
      role: {
        suggestions: [],
        currentRole: null,
        currentRoleName: null,
        selected: [],
      },
      departmentOptions: [{ value: null, text: "--Select Department--" }],
      showOverlay: false,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getAllDepartments();
      this.role.selected = this.user.roles;
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    onUserUpdateClick() {
      this.showOverlay = true;
      const obj = {
        ...this.user,
        userId: this.user.user_id,
      };
      this.updateUserDetails(obj)
        .then((res) => {
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
          this.closeSidebar();
          this.reloadParentData();
        });
    },
    getAllDepartments() {
      this.departmentOptions = [{ value: null, text: "--Select Department--" }];
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/departments?dont_paginate`,
        params: { dont_paginate: true },
      };
      this.$http(options)
        .then((res) => {
          let newDepartments = res.data.data;
          for (var i = 0; i < newDepartments.length; i++) {
            this.departmentOptions.push({
              value: newDepartments[i]._id,
              text: newDepartments[i].title,
            });
          }
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleNewDepartmentSubmit() {
      this.showOverlay = true;
      const bodyFormData = new FormData();
      bodyFormData.append("title", this.newDepartment.title);
      bodyFormData.append("description", this.newDepartment.description);
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/departments`,
        data: bodyFormData,
      };
      this.$http(options)
        .then((resp) => {
          this.getAllDepartments();
          this.hideModal();
          this.handleResponse(resp);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getRolesBySearchPhraseAndSetData(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getRoles({ search: searchPhrase })
            .then((res) => {
              this.role.suggestions = [];
              const newRoles = res.data.data.data;
              if (newRoles.length !== 0) {
                this.role.suggestions.push({
                  name: "roles",
                  data: newRoles,
                });
              }
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        }, 300);
      } else {
        this.role.suggestions = [];
      }
    },

    onRoleSelected(val) {
      if (val) {
        this.addRoleToUser({ roleId: val.item._id, userId: this.user.user_id })
          .then((res) => {
            this.handleResponse(res);
            this.reloadParentData();
            const selectedRole = val.item;
            const roleIndex = this.role.selected.findIndex(
              (role) => role.name === selectedRole.name
            );
            if (roleIndex === -1) {
              this.tagName = "";
              this.role.selected.push(selectedRole);
            }
          })
          .catch((err) => {
            this.handleError(err);
          });
        console.log(val);
      }
    },

    removeRole(roleId) {
      this.removeRoleFromUser({ roleId, userId: this.user.user_id })
        .then((res) => {
          this.handleResponse(res);
          let newSelectedRoles = this.role.selected;
          newSelectedRoles = newSelectedRoles.filter(
            (role) => role._id !== roleId
          );
          this.role.selected = newSelectedRoles;
          this.reloadParentData();
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    getRoleSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
  },
};
</script>

<style>
</style>