var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"my-modal",attrs:{"hide-footer":"","title":"Create New department","size":"lg"}},[_c('validation-observer',{ref:"CreateNewDepartmentForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleNewDepartmentSubmit.apply(null, arguments)}}},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Name"}},[_c('template',{slot:"label"},[_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"Name"},model:{value:(_vm.newDepartment.title),callback:function ($$v) {_vm.$set(_vm.newDepartment, "title", $$v)},expression:"newDepartment.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description","rows":"3","max-rows":"6"},model:{value:(_vm.newDepartment.description),callback:function ($$v) {_vm.$set(_vm.newDepartment, "description", $$v)},expression:"newDepartment.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" Create ")])],1)],1)]}}])})],1),_c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-bottom"},[_c('div',{staticStyle:{"width":"50%"}},[_c('h2',{staticClass:"mb-0"},[_vm._v("Edit User Information")])]),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.closeSidebar()}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)]),_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"UserEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.onUserUpdateClick.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"First Name"}},[_c('template',{slot:"label"},[_vm._v(" First Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"firstname","name":"First Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","state":errors.length > 0 ? false : null,"name":"first-name"},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Last Name"}},[_c('template',{slot:"label"},[_vm._v(" Last Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"lastname","name":"Last Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","state":errors.length > 0 ? false : null,"name":"last-name"},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Roles"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.role.suggestions,"limit":5,"id":"autosuggest__input","input-props":_vm.roleInputProps,"get-suggestion-value":_vm.getRoleSuggestionValue},on:{"input":_vm.getRolesBySearchPhraseAndSetData,"selected":_vm.onRoleSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(_vm.role.currentRole),callback:function ($$v) {_vm.$set(_vm.role, "currentRole", $$v)},expression:"role.currentRole"}}),_c('div',{staticClass:"demo-inline-spacing"},[_vm._l((_vm.role.selected),function(value,i){return [_c('b-badge',{key:i,attrs:{"variant":"light-primary"}},[_c('span',[_vm._v(_vm._s(value.name))]),_c('feather-icon',{staticClass:"text-danger cursor-pointer ml-25",attrs:{"icon":"XCircleIcon"},on:{"click":function($event){return _vm.removeRole(value._id)}}})],1)]})],2)],1),_c('b-form-group',{attrs:{"label":"Designation"}},[_c('template',{slot:"label"},[_vm._v(" Designation "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"designation","name":"Designation","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"designation","state":errors.length > 0 ? false : null,"name":"designation"},model:{value:(_vm.user.designation),callback:function ($$v) {_vm.$set(_vm.user, "designation", $$v)},expression:"user.designation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-8"},[_c('b-form-group',{attrs:{"label":"Department"}},[_c('template',{slot:"label"},[_vm._v(" Department "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"selected":"","options":_vm.departmentOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.user.department_id),callback:function ($$v) {_vm.$set(_vm.user, "department_id", $$v)},expression:"user.department_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1),_c('div',{staticClass:"col"},[_c('b-button',{staticStyle:{"margin-top":"14px"},attrs:{"type":"button","variant":"primary"},on:{"click":_vm.showModal}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("New Department")])],1)],1)]),_c('b-button',{staticClass:"mr-1 mt-2",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_c('feather-icon',{staticClass:"cursor-pointer mr-25",attrs:{"icon":"CheckIcon"}}),_vm._v(" Update User ")],1)],1)]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }