var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showOverlay,"opacity":"0.17","blur":"1rem","rounded":"md","variant":"secondary"}},[_c('b-card',{staticStyle:{"box-shadow":"none !important"}},[_c('div',{staticClass:"card-header border-bottom"},[_c('div',{staticStyle:{"width":"50%"}},[_c('h4',{staticClass:"mb-0 font-weight-bolder"},[_vm._v("Add a new member")])]),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":_vm.handleCloseButtonClick}},[_c('feather-icon',{attrs:{"size":"24","icon":"XIcon"}})],1)],1)]),_c('b-card-body',[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-aside align-self-start"})]),_c('validation-observer',{ref:"user_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var untouched = ref.untouched;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitClick.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"First Name"}},[_c('template',{slot:"label"},[_vm._v(" First Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"firstname","name":"First Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","state":errors.length > 0 ? false : null,"name":"first-name"},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name"}},[_c('template',{slot:"label"},[_vm._v(" Last Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"lastname","name":"Last Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","state":errors.length > 0 ? false : null,"name":"last-name"},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('template',{slot:"label"},[_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Mobile"}},[_c('template',{slot:"label"},[_vm._v(" Mobile "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"mobile","name":"Mobile","rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile","type":"number","state":errors.length > 0 ? false : null,"name":"mobile"},model:{value:(_vm.user.mobile),callback:function ($$v) {_vm.$set(_vm.user, "mobile", $$v)},expression:"user.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Role"}},[_c('template',{slot:"label"},[_vm._v(" Role "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"role_id","name":"Role","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.user.role.options},model:{value:(_vm.user.role.selected),callback:function ($$v) {_vm.$set(_vm.user.role, "selected", $$v)},expression:"user.role.selected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"vid":"should_invite","name":"Invitation Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"should_invite","value":true,"unchecked-value":false},model:{value:(_vm.user.should_invite),callback:function ($$v) {_vm.$set(_vm.user, "should_invite", $$v)},expression:"user.should_invite"}},[_vm._v(" Send invitation email to user ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-75",attrs:{"sm":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid || untouched}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CheckCircleIcon"}}),_c('span',{staticClass:"text-center"},[_vm._v("Submit")])],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }