export default {
  methods: {
    getSubscriptions(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/subscriptions`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getSubscription(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/subscriptions/${id}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    storeSubscription(data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (data[key] && data[key] != null) {
            formData.append(key, data[key]);
          }
        });

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/subscriptions`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateSubscription(id, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("_method", "PUT");
        Object.keys(data).forEach((key) => {
          if (data[key] && data[key] != null) {
            formData.append(key, data[key]);
          }
        });

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/subscriptions/${id}`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
