<template>
  <div class="w-100 h-auto">
    <div class="card">
      <div class="card-header pb-75" style="padding-right: 0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-12"
          >
            <h3 class="mb-25 font-weight-bolder">Deleted Users</h3>
            <p class="mb-50">Manage all users that were deleted.</p>
          </div>
          <div class="col-md-6 col-sm-12">
            <div
              class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
            >
              <!-- <b-button
                size="sm"
                variant="success"
                @click="handleNewToolClick"
              >
                <feather-icon icon="PlusIcon" class="mr-50" /><span
                  class="align-middle"
                  >New Tool</span
                >
              </b-button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search name..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div>
      <div class="position-relative table-responsive" style="min-height: 50vh">
        <b-overlay
          :show="showOverlay"
          opacity="0.17"
          blur="1rem"
          rounded="md"
          variant="secondary"
        >
          <table role="table" class="table">
            <thead role="rowgroup">
              <tr role="row">
                <th scope="col">#</th>
                <th scope="col">Full Name</th>
                <!-- <th scope="col">Email</th> -->
                <th scope="col">Type</th>
                <th scope="col" class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template v-if="Object.keys(users).length > 0">
                <tr role="row" v-for="(user, key, index) in users" :key="key">
               
                  <th scope="row" class="text-center mx-1" style="width: 120px">
                    {{ index + 1 + (filters.page - 1) * 10 }}
                  </th>

                  <td role="cell">
                    <div
                      class="d-flex flex-column align-items-start justify-content-start"
                    >
                      <p
                        class="mb-0 font-weight-bold text-truncate"
                        style="max-width: 240px"
                        v-b-tooltip.hover.bottom.v-primary
                        :title="`${user.firstname} ${user.lastname}`"
                      >
                        {{ `${user.firstname} ${user.lastname}` }}
                      </p>
                    </div>
                  </td>
                  <!-- <td role="cell">
                    <div
                      class="d-flex align-items-center justify-content-start"
                      v-b-tooltip.hover.bottom.v-primary
                      :title="user.email"
                    >
                      <p
                        class="mb-0 font-weight-bold text-truncate"
                        style="max-width: 240px"
                      >
                        {{ user.email }}
                      </p>
                    </div>
                  </td> -->

                  <td>
                    <small class="text-success font-weight-bold">
                      <b-badge variant="primary">{{
                        mapUserType(user.type)
                      }}</b-badge></small
                    >
                  </td>
                  <td
                    role="cell"
                    class="align-center flex-display text-align-right"
                  >
                    <div class="d-flex justify-content-center">
                      <b-dropdown
                        size="sm"
                        text="Select"
                        variant="primary"
                        dropleft
                      >
                        <b-dropdown-item @click="handleRestoreUserClick(user.user_id)"
                          ><span class="align-middle">
                            <feather-icon icon="RepeatIcon" class="mr-50" />
                            <span class="align-middle">Restore</span>
                          </span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          @click="handleDeleteUserPermanentlyClick(user.user_id)"
                          ><span class="align-middle">
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span class="align-middle">Delete Permenantly</span>
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="Users Empty">
                  <template #content>
                    <p class="font-weight-bold text-center">
                      No deleted users have been found.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>
        </b-overlay>
      </div>
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="filters.page"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
  BSidebar,
  BOverlay,
} from "bootstrap-vue";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import UserMixins from "@/mixins/UserMixins";

export default {
  mixins: [ResponseMixins, UserMixins],
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    EmptyTableSection,
    BSidebar,
    BOverlay,
  },
  data() {
    return {
      pagination_pos: "center",

      showOverlay: false,
      pagination: {
        perPage: 10,
        total: 1,
      },

      filters: {
        search: null,
        page: 1,
      },

      users: {},
      selectedUserId: null,
    };
  },
  watch: {
    filters: {
      handler: function (newValues) {
        this.getDeletedUsersAndSetData(newValues);
      },
      deep: true,
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load: function () {
      this.getDeletedUsersAndSetData(this.filters);
    },

    getDeletedUsersAndSetData(params) {
      this.showOverlay = true;
      this.getUsers({ ...params, only_deleted: 1 })
        .then((res) => {
          console.log(res);
          const newUsers = {};
          res.data.data.data.forEach((data) => (newUsers[data._id] = data));
          this.users = newUsers;
          this.$set(this.pagination, "total", res.data.data.data.total);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    permanentlyDeleteUserAndSetData(id) {
      this.showOverlay = true;

      const newParams = {
        force_delete: 1,
        sso_id: id,
      };
      this.deleteUser(newParams)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
          this.load();
        });
    },

    restoreUserAndSetData(id) {
      this.showOverlay = true;

      const newParams = {
        sso_id: id,
      };
      this.restoreUser(newParams)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleDeleteUserPermanentlyClick(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete this User permanently",
          {
            title: "Warning",
            size: "sm",
            headerBgVariant: "light-warning",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.permanentlyDeleteUserAndSetData(id);
          }
        });
    },

    handleRestoreUserClick(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to restore this User", {
          title: "Warning",
          size: "sm",
          headerBgVariant: "light-warning",
          okVariant: "success",
          okTitle: "Restore",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.restoreUserAndSetData(id);
          }
        });
    },
  },
};
</script>
  
<style scoped>
.table th {
  text-transform: none;
}
</style>
  
<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>