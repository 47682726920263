<template>
  <div class="w-100 h-auto" style="min-height: 10vh">
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <b-popover
        target="popover-transfer"
        variant="primary"
        triggers="hover"
        placement="top"
      >
        <template #title>
          <span>Transfer & Delete</span>
        </template>
        <span
          >All the selected properties will be transferred to the selected user
          before deletion.</span
        >
      </b-popover>

      <b-popover
        target="popover-no-transfer"
        variant="danger"
        triggers="hover"
        placement="top"
      >
        <template #title>
          <span>Delete Without Transfer</span>
        </template>
        <span
          >If you're sure and want to proceed with the deletion without
          transferring properties, choose this option.</span
        >
      </b-popover>
      <div class="w-100 d-flex align-items-center justify-content-center">
        <p class="mb-0 font-weight-bold">
          Please choose one of the following options
        </p>
      </div>
      <b-form-group>
        <div
          class="demo-inline-spacing w-100 d-flex align-items-center justify-content-center"
        >
          <b-form-radio
            v-model="transferProperties"
            name="transfer"
            class="mt-75"
            :value="true"
          >
            <span class="align-middle">Transfer</span
            ><feather-icon
              id="popover-transfer"
              class="ml-25 cursor-pointer text-warning"
              icon="HelpCircleIcon"
            />
          </b-form-radio>

          <b-form-radio
            v-model="transferProperties"
            name="do_not_transfer"
            class="mt-75"
            :value="false"
          >
            <span class="align-middle">Do not transfer</span
            ><feather-icon
              id="popover-no-transfer"
              class="ml-25 cursor-pointer text-warning"
              icon="HelpCircleIcon"
            />
          </b-form-radio>
        </div>
      </b-form-group>

      <template v-if="transferProperties === true">
        <div
          class="w-100 h-auto rounded border-light p-1 d-flex flex-column align-items-center justify-content-center"
        >
          <b-form-group v-slot="{ ariaDescribedby }" class="w-100">
            <label class="w-100">
              <span
                class="w-100 d-flex align-items-center justify-content-between"
              >
                <h6 class="mb-25 font-weight-bold">
                  <b-badge variant="primary" class="mr-25">1</b-badge>Properties
                </h6>

                <b-form-checkbox
                  :value="true"
                  v-model="selectAllProperties"
                  class="mb-50 custom-control-primary"
                  >Select All</b-form-checkbox
                >
              </span>
            </label>
            <b-form-checkbox-group
              id="checkbox-group"
              v-model="selectedProperties"
              :aria-describedby="ariaDescribedby"
              name="properties"
            >
              <div
                class="w-100 d-flex flex-column align-items-start justify-content-between flex-wrap px-75"
                style="max-height: 200px"
              >
                <template v-for="property in properties">
                  <b-form-checkbox
                    :disabled="selectAllProperties === true"
                    :key="property.value"
                    :value="property.value"
                    class="mb-50 custom-control-warning"
                    >{{ property.name }}</b-form-checkbox
                  >
                </template>
              </div>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group class="mt-25 w-100">
            <label
              ><h6 class="mb-0 font-weight-bold">
                <b-badge variant="primary" class="mr-25">2</b-badge>
                Select a user to transfer properties before deletion
              </h6></label
            >
            <b-input-group>
              <b-form-select
                v-model="transferableUserId"
                :options="userOptions"
                class="w-100"
                placeholder="Select.."
              />
              <!-- <b-input-group-append>
                <b-button
                  :disabled="
                    !transferableUserId || selectedProperties.length === 0
                  "
                  variant="outline-warning"
                  @click="handleTransferClick"
                >
                  <feather-icon icon="TrashIcon" class="mr-50" />Transfer &
                  Delete
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
          </b-form-group>

          <b-form-group class="w-100 mt-1">
            <label
              ><h6 class="mb-25 font-weight-bold">
                <b-badge variant="primary" class="mr-25">3</b-badge>
                Finish
              </h6></label
            >
            <div class="w-100 d-flex align-items-start justify-content-center">
              <b-button
                :disabled="
                  !transferableUserId || selectedProperties.length === 0
                "
                variant="warning"
                @click="handleTransferClick"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />Transfer & Delete
              </b-button>
            </div>
          </b-form-group>
        </div>
      </template>
      <template v-else>
        <div
          class="w-100 h-auto rounded border-light p-1 d-flex flex-column align-items-center justify-content-center"
        >
          <p class="text-center align-middle text-warning">
            Are you sure that you want to delete this user?
          </p>
          <div class="w-100 d-flex align-items-center justify-content-center">
            <b-button
              @click="handleNoClick"
              variant="outline-secondary"
              class="mr-1"
              >No</b-button
            >
            <b-button @click="deleteUserAndSetData" variant="danger"
              >Delete</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BSidebar,
  BBadge,
  VBModal,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  VBToggle,
  BCollapse,
  BModal,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormGroup,
  BPopover,
  BFormRadio,
  BFormCheckboxGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import UserMixins from "@/mixins/UserMixins";

export default {
  mixins: [ResponseMixins, UserMixins],
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  components: {
    BPagination,
    BButton,
    BSidebar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCollapse,
    BModal,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormGroup,
    BPopover,
    BFormRadio,
    BFormCheckboxGroup,
    BFormCheckbox,
  },

  props: {
    removableUser: {
      type: String,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      showOverlay: false,
      transferProperties: true,
      users: [],
      transferableUserId: null,
      selectAllProperties: false,
      selectedProperties: [],
      properties: [
        {
          name: "Assets",
          value: "assets",
        },
        {
          name: "Gap Assessments",
          value: "gap_assessments",
        },
        {
          name: "Gap Assessment Responses",
          value: "gap_assessment_responses",
        },
        {
          name: "Incident Registers",
          value: "incident_registers",
        },
        {
          name: "Legal Assessments",
          value: "legal_assessments",
        },
        {
          name: "Legal Registers",
          value: "legal_registers",
        },
        {
          name: "Periodic Activities",
          value: "periodic_activity",
        },

        {
          name: "Risk Assessments",
          value: "risk_assessments",
        },

        {
          name: "Risk Registers",
          value: "risk_registers",
        },

        {
          name: "Tasks",
          value: "tasks",
        },
      ],
    };
  },

  watch: {
    selectAllProperties(newValue) {
      if (newValue === true) {
        let newSelected = [];
        this.properties.forEach((property) => {
          newSelected.push(property.value);
        });
        this.selectedProperties = newSelected;
      }
    },
  },

  computed: {
    userOptions() {
      const newUserOptions = [
        { text: "Select", value: null },
        ...this.users
          .filter((user) => {
            if (
              this.removableUser &&
              user.user_id != this.removableUser.user_id
            ) {
              return true;
            }
            return false;
          })
          .map((user) => {
            return {
              text: `${user.firstname} ${user.lastname}`,
              value: user.user_id,
            };
          }),
      ];
      return newUserOptions;
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getUsersAndSetData();
    },

    handleNoClick() {
      this.closeAction();
    },

    getUsersAndSetData() {
      this.showOverlay = true;
      this.getUsers({ dont_paginate: true })
        .then((res) => {
          let newUsers = res.data.data;
          this.users = newUsers;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleTransferClick() {
      this.showOverlay = true;
      const newParams = {
        sso_id: this.removableUser.user_id,
        assignee_id: this.transferableUserId,
      };
      this.selectedProperties.forEach((property, i) => {
        newParams[`transfer[${i}]`] = property;
      });
      this.deleteUser(newParams)
        .then((res) => {
          this.handleResponse(res);
          this.closeAction();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
          this.reloadParent();
        });
    },

    deleteUserAndSetData() {
      this.showOverlay = true;
      const newParams = {
        sso_id: this.removableUser.user_id,
      };
      this.deleteUser(newParams)
        .then((res) => {
          this.handleResponse(res);
          this.closeAction();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
          this.reloadParent();
        });
    },
  },
};
</script>

<style>
</style>