<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="auth-wrapper auth-v2 d-flex justify-content-center">
      <b-col sm="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="4" md="6" lg="12" class="px-2 mx-auto">
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-center"
          >
            Change Password 🔒
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            Your new password must be different from previously used passwords
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password"
                    >Current Password <span class="text-danger">*</span></label
                  >
                </div>

                <validation-provider
                  #default="{ errors }"
                  name="Current Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="currentPassword"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">New Password <span class="text-danger">*</span></label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      @input="password_check"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="row mt-1">
                  <div class="col-6">
                    <p
                      class="frmValidation"
                      :class="{ 'frmValidation--passed': password.length > 9 }"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="frmIcon"
                        :class="password.length ? 'fa-check' : 'fa-times'"
                      />
                      At least 10 characters
                    </p>
                    <p
                      class="frmValidation"
                      :class="{ 'frmValidation--passed': has_uppercase }"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="frmIcon"
                        :class="has_uppercase ? 'fa-check' : 'fa-times'"
                      />
                      Has a capital letter
                    </p>
                  </div>
                  <div class="col-6">
                    <p
                      class="frmValidation"
                      :class="{ 'frmValidation--passed': has_lowercase }"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="frmIcon"
                        :class="has_lowercase ? 'fa-check' : 'fa-times'"
                      />
                      Has a lowercase letter
                    </p>
                    <p
                      class="frmValidation"
                      :class="{ 'frmValidation--passed': has_number }"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="frmIcon"
                        :class="has_number ? 'fa-check' : 'fa-times'"
                      />
                      Has a number
                    </p>
                  </div>
                  <div class="col-12">
                    <p
                      class="frmValidation"
                      :class="{ 'frmValidation--passed': has_special }"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="frmIcon"
                        :class="has_special ? 'fa-check' : 'fa-times'"
                      />
                      Has a special character
                    </p>
                  </div>
                </div>
              </b-form-group>

              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Confirm Password <span class="text-danger">*</span></label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Confirm"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-confirm"
                      v-model="confirm"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="reset-confirm"
                      placeholder="············"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
                :disabled="
                  (password !== '' && !has_number) ||
                  (password !== '' && !has_lowercase) ||
                  (password !== '' && !has_uppercase) ||
                  (password !== '' && !has_special) ||
                  (password !== '' && password.length < 10)
                "
              >
                <span v-if="loading">Please wait</span>
                <span v-else>Reset Password</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCard,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import UserMixins from "../../mixins/UserMixins";
import ResponseMixins from "../../mixins/ResponseMixins";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    BCard,
    BOverlay,
  },
  mixins: [togglePasswordVisibility, UserMixins, ResponseMixins],
  data() {
    return {
      showOverlay: false,
      currentPassword: "",
      password: "",
      confirm: "",
      sideImg: require("@/assets/images/pages/reset-password-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,

      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      loading: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/reset-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    password_check: function () {
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        this.showOverlay = true;
        this.loading = true;
        this.resetLoggedInUserPassword({
          currentPassword: this.currentPassword,
          password: this.password,
          confirm: this.confirm,
        })
          .then((res) => {
            this.handleResponse(res);
            this.resetFormData();
            this.$router.push("/");
            this.loading = false;
          })
          .catch((err) => {
            this.handleError(err);
            console.log(err);
            this.loading = false;
          })
          .finally(() => (this.showOverlay = false)((this.loading = false)));
      });
    },
    resetFormData() {
      this.currentPassword = "";
      this.password = "";
      this.confirm = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.frmField {
  background-color: white;
  color: #495057;
  line-height: 1.25;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  border: 0;
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  width: 90%;
}
.frmLabel {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.frmValidation {
  font-size: 13px;
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: #eb0029;
}
.frmValidation--passed .frmIcon {
  color: #0fa140;
}
</style>
