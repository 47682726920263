<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card">
      <div class="card-header bg-light-secondary">
        <div style="width: 70%">
          <h4 class="font-weight-bolder">Manage Permissions of {{ role.display_name }}</h4>
          <p>{{ role.description }}</p>
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <b-button size="sm" @click="closeSidebar()" variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>
      <div class="card-body mt-3">
        <div class="row">
          <div class="col-6">
            <AddOrRemoveList
              :items="unAssignedPermissions"
              title="Unassigned Permissions"
              :buttonAddType="true"
              :buttonFunction="handleAddPermissionClick"
              :searchFunction="handleUnAssignedPermissionSearch"
            />
          </div>
          <div class="col-6">
            <AddOrRemoveList
              :items="role.permissions"
              title="Assigned Permissions"
              :buttonAddType="false"
              :buttonFunction="handleRemovePermissionClick"
              :searchFunction="handleAssignedPermissionSearch"
            />
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BButton, BBadge, BOverlay } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import PermissionMixins from "../../../mixins/PermissionMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import AddOrRemoveList from "../../../components/AddOrRemoveList.vue";
export default {
  name: "RoleDetailsForm",
  components: {
    BButton,
    VueAutosuggest,
    BBadge,
    AddOrRemoveList,
    BOverlay,
  },
  data() {
    return {
      permissionName: null,
      permissionOptions: [],
      selectedPermissions: [],
      permissionInputProps: {
        class: "form-control",
        placeholder: "Search Permissions..",
      },

      permissions: [],
      assignedPermissionfilters: {
        search: null,
      },
      showOverlay: false,
      unAssignedPermissions: [],
      unassignedPermissionfilters: {
        search: null,
        dont_paginate:1,
      },
    };
  },
  mixins: [PermissionMixins, ResponseMixins],
  mounted() {
    this.load();
  },
  props: {
    role: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    loadRoles: {
      type: Function,
      required: true,
    },
  },
  watch: {
    unassignedPermissionfilters: {
      handler: function (value) {
        this.getUnassignedPermissionsAndSetOptions(value);
      },
      deep: true,
    },
    assignedPermissionfilters: {
      handler: function (value) {
        this.filterAssignedPermissions(value);
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.getUnassignedPermissionsAndSetOptions(
        this.unassignedPermissionfilters
      );
    },
    getUnassignedPermissionsAndSetOptions(filters) {
      this.showOverlay = true;
      this.getPermissions(filters)
        .then((res) => {
          let newUnAssignedPermissions = res.data.data;
          for (let i = 0; i < this.role.permissions.length; i++) {
            newUnAssignedPermissions = newUnAssignedPermissions.filter(
              (p) => p._id !== this.role.permissions[i]._id
            );
          }
          this.unAssignedPermissions = newUnAssignedPermissions;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    filterAssignedPermissions(filters) {
      let newPermissions = [];
      if(filters.search==="" || filters.search===null){
        this.loadRoles()
      }
      else{
         for (let i = 0; i < this.role.permissions.length; i++) {
        if (this.role.permissions[i].name.includes(filters.search)) {
          newPermissions.push(this.role.permissions[i]);
        }
      }
      }
      this.role.permissions = newPermissions;
    },
    handleUnAssignedPermissionSearch(searchPhrase) {
      this.unassignedPermissionfilters.search = searchPhrase;
    },

    handleAssignedPermissionSearch(searchPhrase) {
      this.assignedPermissionfilters.search = searchPhrase;
    },

    handleAddPermissionClick(permissionId) {
      this.addPermissionToRoleAndSetData(permissionId);
    },

    handleRemovePermissionClick(permissionId) {
      this.removePermissionFromRoleAndSetData(permissionId);
    },

    addPermissionToRoleAndSetData(permissionId) {
      this.showOverlay = true;
      this.addPermissionToRole(this.role._id, permissionId)
        .then((res) => {
          let permissionIndex = this.unAssignedPermissions.findIndex(
            (p) => p._id === permissionId
          );
          if (permissionIndex !== -1) {
            this.role.permissions.push(
              this.unAssignedPermissions[permissionIndex]
            );
            let newUnAssignedPermissions = this.unAssignedPermissions.filter(
              (p) => p._id !== permissionId
            );
            this.unAssignedPermissions = newUnAssignedPermissions;
          }
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    removePermissionFromRoleAndSetData(permissionId) {
      this.showOverlay = true;
      this.removePermissionFromRole(this.role._id, permissionId)
        .then((res) => {
          
          let permissionIndex = this.role.permissions.findIndex(
            (p) => p._id === permissionId
          );
          if (permissionIndex !== -1) {
            
            this.unAssignedPermissions.push(
              this.role.permissions[permissionIndex]
            );
            let newPermissions = this.role.permissions.filter(
              (p) => p._id !== permissionId
            );
            this.role.permissions = newPermissions;
          }
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

  },
};
</script>

<style>
</style>