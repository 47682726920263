<template>
  <div>
    <b-modal
      id="transfer_modal"
      ref="transfer_modal"
      hide-footer
      header-bg-variant="light-warning"
      header-text-variant="warning"
      title="Warning"
      size="lg"
    >
      <template #modal-title>
        <p class="mb-0 font-weight-bold align-middle text-warning">
          <feather-icon icon="AlertTriangleIcon" class="mr-25" /><span
            >Warning</span
          >
        </p>
      </template>
      <user-delete-or-transfer-widget
        v-if="removableUser"
        :removableUser="removableUser"
        :closeAction="hideTransferModal"
        :reloadParent="load"
      />
    </b-modal>
    <b-modal
      id="invite_modal"
      ref="invite_modal"
      hide-footer
      title="Invite User"
      size="lg"
    >
      <div
        class="d-flex flex-column align-items-center justify-content-start p-1"
      >
        <b-input-group>
          <b-form-input v-model="invitationURL" ref="invitation_url" />
          <b-input-group-append>
            <b-button variant="outline-primary" @click="handleCopyClick">
              <feather-icon icon="CopyIcon" class="mr-50" />Copy
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <div
          class="w-100 d-flex align-items-center justify-content-center my-1"
        >
          <h5>OR</h5>
        </div>
        <b-button variant="success" @click="handleInviteOnMailClick">
          <feather-icon icon="MailIcon" class="mr-50" /> Send invitation via
          Mail
        </b-button>
      </div>
    </b-modal>
    <b-sidebar
      id="sidebar-backdrop"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      width="580px"
      lazy
    >
      <UserAddForm :closeSidebar="closeSidebar" :reloadParentData="load" />
    </b-sidebar>

    <b-sidebar
      id="user-edit-sidebar"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="580px"
    >
      <UserEditForm
        v-if="selectedUserIndex !== null"
        :closeSidebar="closeUserEditSidebar"
        :reloadParentData="load"
        :user="users[selectedUserIndex]"
      />
    </b-sidebar>

    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card">
        <div class="card-header">
          <h2>User Management</h2>
        </div>

        <div class="card-body">
          <b-button variant="success" class="mb-1" @click="openSidebar()"
            ><feather-icon icon="PlusIcon" class="mr-50" />Add a user</b-button
          >
          <div
            class="table-wrapper-scroll-y my-custom-scrollbar"
            style="width: 100%"
          >
            <table role="table" class="table">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Full Name</th>
                  <!-- <th role="columnheader" scope="col">Email</th> -->
                  <th role="columnheader" scope="col">Department</th>
                  <th role="columnheader" scope="col">Designation</th>
                  <th role="columnheader" scope="col">Roles</th>
                  <th role="columnheader" scope="col">Actions</th>
                </tr>
              </thead>

              <tbody role="rowgroup">
                <template v-for="user in users">
                  <tr :key="user._id" role="row">
                    <td role="cell" style="width: 100px">
                      <div
                        class="d-flex align-items-center justify-content-start"
                        style="width: 100px"
                      >
                        <p
                          v-if="user && user != null"
                          class="mb-0 font-weight-bolder text-truncate"
                          v-b-tooltip.hover.top.v-secondary
                          :title="fullName(user)"
                        >
                          {{ fullName(user) }}
                        </p>
                        <b-badge v-else variant="danger">Deleted User</b-badge>
                      </div>
                    </td>

                    <!-- <td role="cell" style="width: 120px; padding-right: 0">
                      <div
                        class="d-flex align-items-center justify-content-start"
                        style="width: 120px"
                        v-if="user.email && user.email !== null"
                      >
                        <p
                          class="mb-0 font-weight-bold text-truncate"
                          v-b-tooltip.hover.top.v-secondary
                          :title="user.email"
                        >
                          {{ user.email }}
                        </p>
                      </div>
                    </td> -->

                    <td role="cell" style="width: 120px; padding-right: 0">
                      <div
                        v-if="user.department && user.department !== null"
                        class="d-flex align-items-center justify-content-start"
                        style="width: 120px"
                        v-b-tooltip.hover.top.v-secondary
                        :title="user.department.title"
                      >
                        <p class="mb-0 font-weight-bold text-truncate">
                          {{ user.department.title }}
                        </p>
                      </div>
                    </td>

                    <td role="cell" style="width: 120px; padding-right: 0">
                      <div
                        v-if="user.designation && user.designation !== null"
                        class="d-flex align-items-center justify-content-start"
                        style="width: 120px"
                        v-b-tooltip.hover.top.v-secondary
                        :title="user.designation"
                      >
                        <p
                          v-if="user.designation"
                          class="mb-0 font-weight-bold text-truncate"
                        >
                          {{ user.designation }}
                        </p>
                      </div>
                    </td>

                    <td role="cell" style="width: 100px; padding-right: 0">
                      <div
                        :v-if="user.roles || user.roles.length > 0"
                        class="d-flex flex-column justify-content-start align-items-start"
                        style="width: 100px"
                      >
                        <template v-for="role in user.roles">
                          <b-badge
                            :key="role._id"
                            variant="light-primary"
                            style="width: 100px"
                            v-b-tooltip.hover.bottom.v-secondary
                            :title="role.display_name"
                            class="mb-25"
                            ><p
                              style="width: 88px; line-height: 13px"
                              class="text-truncate p-0 m-0"
                            >
                              {{ role.display_name }}
                            </p>
                          </b-badge>
                        </template>
                      </div>
                    </td>
                    <td role="cell">
                      <!-- <button
                      class="btn btn-outline-primary btn-sm mr-50"
                      v-if="!user.is_previously_logined"
                      @click="inviteUser(user.user_id)"
                    >
                      Invite
                    </button>
                    <button
                      class="btn btn-outline-primary btn-sm"
                      @click.stop="handleUserEditClick(user.user_id)"
                    >
                      Edit
                    </button> -->
                      <b-dropdown
                        variant="outline-primary"
                        size="sm"
                        text="Select"
                      >
                        <b-dropdown-item
                          @click.stop="handleUserEditClick(user.user_id)"
                          >Edit</b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="!user.is_previously_logined"
                          @click="handleInviteClick(user.user_id)"
                          >Invite</b-dropdown-item
                        >
                        <b-dropdown-item
                          @click.stop="handleDeleteUserClick(user)"
                          >Delete</b-dropdown-item
                        >
                      </b-dropdown>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div class="m-2">
              <div class="row">
                <div
                  class="d-flex align-items-center justify-content-center col-12"
                >
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    aria-controls="my-table"
                  >
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import UserMixins from "../../mixins/UserMixins";
import RoleMixins from "../../mixins/RoleMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import {
  BPagination,
  BButton,
  BSidebar,
  BBadge,
  VBModal,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  VBToggle,
  BCollapse,
  BModal,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormGroup,
  BPopover,
} from "bootstrap-vue";
import UserAddForm from "./components/UserAddForm.vue";
import UserEditForm from "./components/UserEditForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import UserDeleteOrTransferWidget from "./components/UserDeleteOrTransferWidget.vue";
export default {
  name: "UsersSettings",
  components: {
    BPagination,
    BButton,
    BSidebar,
    UserAddForm,
    BBadge,
    UserEditForm,
    BDropdown,
    BDropdownItem,
    BCollapse,
    BModal,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormGroup,
    FeatherIcon,
    BPopover,
    UserDeleteOrTransferWidget,
  },
  mixins: [UserMixins, ResponseMixins, RoleMixins],
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      showOverlay: false,
      selectedUser: null,
      removableUser: null,
      users: [],
      unAssignedRoles: [],
      selectedUserIndex: null,
      invitingUserId: null,
      pagination: {
        currentPage: 1,
        totalRows: 10,
        perPage: 10,
      },
      invitationURL: null,
    };
  },

  computed: {
    userOptions() {
      return this.users
        .filter((user) => {
          if (
            this.removableUser &&
            user.user_id != this.removableUser.user_id
          ) {
            return true;
          }
          return false;
        })
        .map((user) => {
          return {
            text: `${user.firstname} ${user.lastname}`,
            value: user.user_id,
          };
        });
    },
  },

  watch: {
    pagination: {
      handler: function (value) {
        this.getUsersAndSetData(value);
      },
      deep: true,
    },
  },

  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getUsersAndSetData(this.pagination);
      this.getRolesAndSetData();
    },

    handleTransferClick() {
      this.showOverlay = true;
      const newParams = {
        sso_id: this.removableUser.user_id,
        assignee_id: this.selectedUser.user_id,
      };
      this.deleteUser(newParams)
        .then((res) => {
          this.handleResponse(res);
          this.hideTransferModal();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
          this.load();
        });
    },

    showTransferModal() {
      this.selectedUser = null;
      this.$refs["transfer_modal"].show();
    },
    hideTransferModal() {
      this.$refs["transfer_modal"].hide();
    },

    deleteUserAndSetData(id) {
      const newParams = {
        sso_id: id,
      };
      this.deleteUser(newParams)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.load();
        });
    },

    handleDeleteUserClick(user) {
      this.removableUser = user;
      this.showTransferModal();
    },

    // handleDeleteUserClick(id) {
    //   this.$bvModal
    //     .msgBoxConfirm("Please confirm that you want to delete this User", {
    //       title: "Warning",
    //       size: "sm",
    //       headerBgVariant: "light-warning",
    //       okVariant: "danger",
    //       okTitle: "Delete",
    //       cancelTitle: "No",
    //       cancelVariant: "outline-secondary",
    //       hideHeaderClose: false,
    //       centered: true,
    //     })
    //     .then((value) => {
    //       if (value) {
    //         this.deleteUserAndSetData(id);
    //       }
    //     });
    // },

    getUserInviteURLAndSetData(userId) {
      this.getUserInviteURL(userId)
        .then((res) => {
          this.invitationURL = res.data.data.invite_link;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    async handleCopyClick() {
      try {
        navigator.clipboard.writeText(this.invitationURL).then((res) => {
          this.showToast("Copied Successfully!", "success");
        });
      } catch ($e) {
        this.showToast("An Error Occured, Please retry!", "warning");
      }
    },

    handleInviteOnMailClick() {
      this.inviteUser(this.invitingUserId);
      this.hideModal();
    },

    showModal() {
      this.$refs["invite_modal"].show();
    },
    hideModal() {
      this.$refs["invite_modal"].hide();
    },

    handleInviteClick(user_id) {
      this.invitingUserId = user_id;
      this.getUserInviteURLAndSetData(user_id);
      this.showModal();
    },

    inviteUser(user_id) {
      const bodyFormData = new FormData();
      bodyFormData.append("user_id", user_id);
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/reinvite-user`,
        data: bodyFormData,
      };
      this.$http(options)
        .then((resp) => {
          console.log(resp);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: resp.data.message,
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },

    getUsersAndSetData(pagination) {
      this.showOverlay = true;
      this.getUsers({ page: pagination.currentPage })
        .then((res) => {
          this.pagination.currentPage = res.data.data.current_page;
          this.pagination.totalRows = res.data.data.last_page * 10;
          let newUsers = res.data.data.data;
          // Remove Current User
          // newUsers = newUsers.filter(
          //   (user) => user.user_id !== this.$store.state.app.user.user_id
          // );
          this.users = newUsers;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    fullName(user) {
      return user.firstname + " " + user.lastname;
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },

    closeUserEditSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "user-edit-sidebar");
    },
    handleUserEditClick(userId) {
      this.openUserEditSidebar(userId);
    },
    openUserEditSidebar: function (id) {
      this.selectedUserIndex = this.users.findIndex(
        (user) => user.user_id === id
      );
      if (this.selectedUserIndex !== -1) {
        this.$root.$emit("bv::toggle::collapse", "user-edit-sidebar");
      }
    },

    showConfirmRemoveMsgBox(userId, roleId) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to Remove this Role.", {
          title: "Remove Role",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.removeRoleFromUserAndSetData({ userId, roleId });
          }
        });
    },

    removeRoleFromUserAndSetData(obj) {
      this.removeRoleFromUser(obj)
        .then((res) => {
          this.handleResponse(res);
          let newUserIndex = this.users.findIndex(
            (user) => user.user_id === obj.userId
          );
          let newUser = this.users[newUserIndex];
          let newUserRoles = newUser.roles.filter(
            (role) => role._id !== obj.roleId
          );
          newUser.roles = newUserRoles;
          this.users[newUserIndex] = newUser;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    addRoleToUserAndSetData(obj) {
      this.addRoleToUser(obj)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    getRolesAndSetData() {
      this.getRoles({ dont_paginate: true })
        .then((res) => {
          this.unAssignedRoles = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table td,
.table th {
  width: 10%;
}
.my-custom-scrollbar {
  position: relative;
  height: 70vh;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>