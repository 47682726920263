<template>
  <b-overlay
    :show="showOverlay"
    opacity="0.17"
    blur="1rem"
    rounded="md"
    variant="secondary"
  >
    <b-card style="box-shadow: none !important">
      <div class="card-header border-bottom">
        <div style="width: 50%">
          <h4 class="mb-0 font-weight-bolder">Add a new member</h4>
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <b-button
            @click="handleCloseButtonClick"
            variant="outline-secondary"
            size="sm"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>

      <b-card-body>
        <div class="media">
          <div class="media-aside align-self-start"></div>
        </div>
        <validation-observer ref="user_form" #default="{ invalid, untouched }">
          <b-form class="mt-2" @submit.prevent="handleSubmitClick">
            <b-row>
              <!-- FirstName -->
              <b-col sm="6">
                <b-form-group label="First Name">
                  <template slot="label">
                    First Name <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    vid="firstname"
                    #default="{ errors }"
                    name="First Name"
                    rules="required|min:3"
                  >
                    <b-form-input
                      id="first-name"
                      v-model="user.firstname"
                      :state="errors.length > 0 ? false : null"
                      name="first-name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- LastName -->
              <b-col sm="6">
                <b-form-group label="Last Name">
                  <template slot="label">
                    Last Name <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    vid="lastname"
                    #default="{ errors }"
                    name="Last Name"
                    rules="required|min:3"
                  >
                    <b-form-input
                      id="last-name"
                      v-model="user.lastname"
                      :state="errors.length > 0 ? false : null"
                      name="last-name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Email -->
              <b-col sm="6">
                <b-form-group label="Email">
                  <template slot="label">
                    Email <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    vid="email"
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="user.email"
                      :state="errors.length > 0 ? false : null"
                      name="email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Mobile -->
              <b-col sm="6">
                <b-form-group label="Mobile">
                  <template slot="label">
                    Mobile <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    vid="mobile"
                    #default="{ errors }"
                    name="Mobile"
                    rules="required|digits:10"
                  >
                    <b-form-input
                      id="mobile"
                      v-model="user.mobile"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      name="mobile"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="12">
                <b-form-group label="Role">
                  <template slot="label">
                    Role <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    vid="role_id"
                    #default="{ errors }"
                    name="Role"
                    :rules="{
                      required: true,
                    }"
                  >
                    <b-form-select
                      v-model="user.role.selected"
                      :options="user.role.options"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    vid="should_invite"
                    name="Invitation Email"
                  >
                    <b-form-checkbox
                      id="should_invite"
                      v-model="user.should_invite"
                      :value="true"
                      :unchecked-value="false"
                    >
                      Send invitation email to user
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- <b-col sm="6">
                <b-form-group label="Role">
                  <validation-provider
                    vid="role_id"
                    #default="{ errors }"
                    name="Role"
                    :rules="{
                      required: true,
                    }"
                  >
                    <vue-autosuggest
                      :suggestions="user.role.options"
                      :limit="5"
                      v-model="user.role.selected.name"
                      id="autosuggest__input"
                      :input-props="user.role.roleInputProps"
                      @input="getRolesBySearchPhraseAndSetOptions"
                      @selected="onRoleSelected"
                      :get-suggestion-value="getRoleSuggestionValue"
                    >
                      <template
                        slot-scope="{ suggestion }"
                        style="display: flex; align-items: center"
                      >
                        <span style="{ display: 'flex', color: 'navyblue'}"
                          >{{ suggestion.item.name }}
                        </span>
                      </template>
                    </vue-autosuggest>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->

              <!-- submit and reset -->
              <b-col sm="12" class="mt-75">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="invalid || untouched"
                >
                  <feather-icon icon="CheckCircleIcon" class="mr-1" />
                  <span class="text-center">Submit</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BOverlay,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { VueAutosuggest } from "vue-autosuggest";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserMixins from "../../../mixins/UserMixins";
import RoleMixins from "../../../mixins/RoleMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";
export default {
  name: "UserAddForm",
  components: {
    BCol,
    BRow,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    Ripple,
    required,
    VueAutosuggest,
    BOverlay,
    FeatherIcon,
    BFormSelect,
  },
  mixins: [UserMixins, RoleMixins, ResponseMixins],
  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    reloadParentData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
      user: {
        firstname: null,
        lastname: null,
        mobile: null,
        email: null,
        should_invite: false,
        role: {
          options: [{ value: null, text: "--Select Role--" }],
          selected: null,
        },
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getRolesAndSetData();
    },

    handleSubmitClick() {
      this.showOverlay = true;
      this.addUserToTenant(this.user)
        .then((res) => {
          this.reloadParentData();
          this.closeSidebar();
          this.showOverlay = false;
          this.handleResponse(res);
          this.setDefaultFormData();
        })
        .catch((err) => {
          this.showOverlay = false;
          console.log(err);
          this.handleError(err);
          if (err.response) {
            if (err.response.status === 422) {
              this.$refs.user_form.setErrors(err.response.data.errors);
            }
          }
        });
      // .finally(() => {
      //   this.showOverlay = false;
      //   this.reloadParentData();
      //   this.closeSidebar()
      // });
    },

    handleCloseButtonClick() {
      this.setDefaultFormData();
      this.closeSidebar();
    },

    setDefaultFormData() {
      this.user.firstname = null;
      this.user.lastname = null;
      this.user.mobile = null;
      this.user.email = null;
      this.user.should_invite = false;
      this.user.role = {
        options: [{ value: null, text: "--Select Role--" }],
        selected: null,
      };
    },

    getRolesAndSetData() {
      this.getRoles({ dont_paginate: true })
        .then((res) => {
          console.log(res.data.data);
          this.user.role.options = [];
          for (let i = 0; i < res.data.data.length; i++) {
            this.user.role.options.push({
              value: res.data.data[i]._id,
              text: res.data.data[i].name,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>

<style>
</style>